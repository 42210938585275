import Layout from "../components/Layout";
import { signIn, useSession } from "next-auth/react";
import { useEffect, useState } from "react";
import Trophy from "../components/Icons/Trophy";
import People from "../components/Icons/People";
import Hand from "../components/Icons/Hand";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { postSignUp } from "../api/user";
import { useRouter } from "next/router";
import Head from "next/head";
import { getServerSideProps } from "./tournaments/[id]/scoreboard";
import { getArticles } from "../api/articles";
import Link from "next/link";
import { formatDistanceToNow, parseISO } from "date-fns";
import fieldImage from "../../public/field.png";
import { TrophyIcon } from "../components/Icons/trophyIcon";
import { PeopleIcon } from "../components/Icons/peopleIcon";
import { TallyIcon } from "../components/Icons/tallyIcon";

const styles = {
  label: "",
  field: "grow rounded m-2 p-2 text-black text-sm font-['Montserrat'] h-[50px]",
  errorMsg:
    "mx-2 px-4 py-1 bg-red-100 border border-red-400 text-red-700 rounded text-left font-serif",
};

export async function getStaticProps() {
  const articles = (await getArticles(6)).map((article) => ({
    title: article.fields.title,
    slug: article.fields.slug,
    updatedAt: article.sys.updatedAt,
    headerImage: article.fields.headerImage.fields.file.url,
  }));

  return { props: { articles, revalidate: 600 } };
}

export default function Home({ articles }) {
  const { data: session }: any = useSession();
  const [isLoading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [signUpError, setSignUpError] = useState("");
  const router = useRouter();
  // console.log(process.env.NEXT_PUBLIC_API_URL);
  // console.log("Session in home is ", session);
  useEffect(() => {
    if (session?.error === "RefreshAccessTokenError") {
      signIn(); // Force sign in to hopefully resolve error
    }
  }, [session]);

  if (session) {
    router.push("/landing");
  }

  const showResultForm = () => {
    if (completed) {
      if (signUpError) {
        return <div>Sign Up Error - {signUpError}</div>;
      } else return CheckYourEmail();
    } else {
      return signUpForm();
    }
  };

  const Articles = (articles) => {
    return (
      <div className="pb-10">
        {/* make Lastest Article text black */}
        <h1 className="pt-10 pb-8 text-4xl text-center drop-shadow-md font-['Montserrat'] text-black">
          Latest Articles
        </h1>
        <div className="px-5 flex w-full justify-center">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-10">
            {articles.map((article) => (
              <div
                key={article.slug}
                className="mb-4 md:mb-10 max-w-sm bg-white rounded overflow-hidden shadow-lg "
              >
                <img
                  className="w-full h-36 object-cover"
                  src={article.headerImage}
                  alt={article.title}
                />
                <div className="px-2 pt-2 md:pb-2 ">
                  <div className="font-bold text-xl text-black md:mb-2">
                    <Link href={"/article/" + article.slug}>
                      {article.title}
                    </Link>
                  </div>
                </div>
                <div className="font-serif px-3 pb-2 text-right text-slate-600">
                  {formatDistanceToNow(parseISO(article.updatedAt))} ago
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const hero = ({ articles }) => (
    <div className="text-white">
      <div
        className=" flex flex-col w-full max-w-full h-fit justify-center text-center bg-cover bg-center"
        style={{
          backgroundImage: `url("${fieldImage.src}")`,
        }}
      >
        <div className="font-['Montserrat'] font-extrabold pt-24 pb-3 text-3xl whitespace-pre-line">
          {"WELCOME TO GOLF CHAMPS\nFANTASY GOLF"}
        </div>
        {showResultForm()}
      </div>
      {HowDoesItWork()}
      {Articles(articles)}
    </div>
  );

  const benefitDescription = (header, description, icon) => {
    return (
      <div className="flex-1 text-center">
        <div className="flex justify-center mb-[20px]">{icon}</div>
        <div className="text-[24px] font-extrabold leading-normal mb-[8px] uppercase">
          {header}
        </div>
        <div className="text-[16px] font-normal leading-normal">
          {description}
        </div>
      </div>
    );
  };

  const HowDoesItWork = () => (
    <div className="py-[60px] px-8 grid grid-cols-1 md:grid-cols-3 gap-4 justify-center text-black text-4xl bg-white font-['Montserrat']">
      {benefitDescription(
        "Pick a team",
        "Take two minutes to pick a team participating in this weekend’s tournament with a combined World Ranking of 100 or more",
        <TrophyIcon size={44} />,
      )}

      {benefitDescription(
        "Create or join pools",
        "Challenge friends, family and colleagues to private or public pools.",
        <PeopleIcon height={45} />,
      )}
      {benefitDescription(
        "Tally your score",
        "Add your four golfers’ actual scores together for the weekend and track your progress up the leaderboard in real time!",
        <TallyIcon height={52} />,
      )}
    </div>
  );

  const CheckYourEmail = () => (
    <div
      className="bg-white flex-col mx-auto mb-10 w-full max-w-lg rounded"
      id="checkYourEmail"
    >
      <svg
        viewBox="0 0 24 24"
        className="text-green-600 w-16 h-16 mx-auto my-6"
      >
        <path
          fill="currentColor"
          d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
        ></path>
      </svg>
      <div className="text-center">
        <h3 className="text-4xl text-base text-gray-900 font-semibold text-center">
          Welcome! One last thing though...
        </h3>
        <p className="text-gray-600 my-2 font-serif">
          Please click on the link
          <br /> in the email sent to you, to confirm your registration
        </p>
        <p className="font-serif my-2 text-gray-400">
          If you do not recieve a mail, please reach out to{" "}
          <a href="mailto:info@golfchamps.net">info@golfchamps.net</a>
        </p>
      </div>
    </div>
  );

  const signUpForm = () => (
    <>
      {Object.keys(formErrors).length !== 0 && (
        <div
          className="flex-col mx-auto mb-2 w-full max-w-lg rounded
      bg-red-100 border border-red-400 text-red-700 rounded font-serif text-left p-2"
        >
          {Object.keys(formErrors).map((key: any) =>
            formErrors[key].map((error) => <p>{error.message}</p>),
          )}
        </div>
      )}

      <Formik
        initialValues={{
          email: "",
          first_name: "",
          last_name: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Your email address is invalid")
            .required("An e-mail address is required"),
          first_name: Yup.string()
            .required("Your first name is required")
            .min(2, "Too Short!"),
          last_name: Yup.string()
            .required("Your surname is required")
            .min(2, "Too Short!"),
          password: Yup.string().required("Your password is required"),
        })}
        onSubmit={(values) => {
          setLoading(true);
          postSignUp(values)
            .then((res) => {
              setLoading(false);
              console.log("Res post sign up is", res);
              // if (res.statusCode != 200) {
              //   setLoading(false);
              //   setCompleted(true);
              //   setSignUpError(res.message);
              // } else {
              //   setLoading(false);
              //   setCompleted(true);
              // }
              if (res.fieldErrors) {
                setFormErrors(res.fieldErrors);
              } else {
                setCompleted(true);
              }
            })
            .catch((error) => {
              setCompleted(true);
              setSignUpError("Unknown error");
              setLoading(false);
            });
        }}
      >
        <div className="flex w-full max-w-full md:max-w-lg mx-auto mb-5">
          <Form className="w-full max-w-full">
            <div className="flex flex-col max-w-full">
              <Field
                className={styles.field}
                id="email"
                name="email"
                placeholder="Email"
              />
              <ErrorMessage
                component="div"
                className={styles.errorMsg}
                name="email"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <Field
                className={[styles.field, "m-0 form-control"].join(" ")}
                id="first_name"
                name="first_name"
                placeholder="First Name"
              />
              <ErrorMessage
                component="div"
                className={[styles.errorMsg, "mx-0 mt-2"].join(" ")}
                name="first_name"
              />
              <Field
                className={[styles.field, "m-0 block form-control"].join(" ")}
                id="last_name"
                name="last_name"
                placeholder="Surname"
              />
              <ErrorMessage
                component="div"
                className={[styles.errorMsg, "mx-0 mt-2"].join(" ")}
                name="last_name"
              />
            </div>
            <div className="flex flex-col max-w-full">
              <Field
                className={styles.field}
                id="password"
                name="password"
                placeholder="Password"
                type="password"
              />
              <ErrorMessage
                component="div"
                className={styles.errorMsg}
                name="password"
              />
            </div>
            <div className="flex max-w-full items-center justify-center p-5 pb-20">
              <button
                type="submit"
                className="rounded-md font-['Montserrat'] mr-5 text-sm font-semibold text-white px-10 py-3 bg-black leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out inline-flex items-center"
                disabled={isLoading}
              >
                Sign Up
                {isLoading && (
                  <svg
                    className="animate-spin -mr-1 ml-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
              </button>
              <button
                className={
                  "rounded-md font-['Montserrat'] text-sm font-semibold text-white px-10 py-3 leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out inline-flex items-center"
                }
              >
                <a
                  href={"/api/auth/signin"}
                  onClick={(e) => {
                    e.preventDefault();
                    signIn("fusionauth", { callbackUrl: "/landing" }).finally(
                      () => {},
                    );
                  }}
                >
                  Log in
                </a>
              </button>
            </div>
          </Form>
        </div>
      </Formik>
    </>
  );

  return (
    <Layout>
      <div className="flex flex-col grow">{hero({ articles })}</div>
    </Layout>
  );
}
