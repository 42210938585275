export function TrophyIcon(props: Props) {
  const { size } = props;
  const { height = size, width = size } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M11.8 18.4H8.5C7.04131 18.4 5.64236 17.8205 4.61091 16.7891C3.57946 15.7576 3 14.3587 3 12.9C3 11.4413 3.57946 10.0424 4.61091 9.01091C5.64236 7.97946 7.04131 7.4 8.5 7.4H11.8M11.8 18.4V3H38.2V18.4M11.8 18.4C11.8 21.9009 13.1907 25.2583 15.6662 27.7338C18.1417 30.2093 21.4991 31.6 25 31.6C28.5009 31.6 31.8583 30.2093 34.3338 27.7338C36.8093 25.2583 38.2 21.9009 38.2 18.4M38.2 18.4H41.5C42.9587 18.4 44.3576 17.8205 45.3891 16.7891C46.4205 15.7576 47 14.3587 47 12.9C47 11.4413 46.4205 10.0424 45.3891 9.01091C44.3576 7.97946 42.9587 7.4 41.5 7.4H38.2M7.4 47H42.6M20.6 30.852V36C20.6 37.21 19.566 38.156 18.466 38.662C15.87 39.85 14 43.128 14 47M29.4 30.852V36C29.4 37.21 30.434 38.156 31.534 38.662C34.13 39.85 36 43.128 36 47"
        stroke="black"
        stroke-width="4.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

interface Props {
  height?: number;
  width?: number;
  size?: number;
}
