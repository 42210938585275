import { API_URL } from "../lib/constants";
import fetchWithRequestId from "./common";

export const postSignUp = (data) => {
  return fetchWithRequestId(`${API_URL}/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (!res.ok && res.status == 500) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const changeUserPassword = (accessToken, data) => {
  return fetchWithRequestId(
    `${API_URL}/users/change-password?email=${data.email}&newPassword=${data.newPassword}`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    },
  )
    .then((res) => {
      if (!res.ok && res.status == 500) {
        throw new Error(res.statusText);
      }
      return res;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const getOldScores = (accessToken, oldUserId) => {
  return fetchWithRequestId(`${API_URL}/pools/historical?userId=${oldUserId}`, {
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

export const getOldPool = (accessToken, poolId) => {
  return fetchWithRequestId(`${API_URL}/pools/${poolId}/historical`, {
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

export const getOldPoolPlayers = (accessToken, poolId, userId) => {
  return fetch(
    `${API_URL}/entries/historical?userId=${userId}&poolId=${poolId}`,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    },
  ).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

export const addFriendRequest = (accessToken, userId) => {
  return fetchWithRequestId(`${API_URL}/users/friend/${userId}`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw res.statusText;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const removeFriendRequest = (accessToken, userId) => {
  return fetchWithRequestId(`${API_URL}/users/friend/${userId}`, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw res.statusText;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const me = (accessToken) => {
  return fetch(`${API_URL}/users/me`, {
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  }).then(async (res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    const data = await res.json();
    return data;
  });
};

export const updateMe = (accessToken, data) => {
  return fetch(`${API_URL}/users/me`, {
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((res) => {
    console.log("Res is", res);
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    const response = res.json();
    console.log("Response is ", response);
    return response;
  });
};

export const getCountries = (accessToken) => {
  return fetch(`${API_URL}/countries`, {
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

export const getCoursesByCountry = (accessToken, country_id) => {
  return fetch(`${API_URL}/courses?countryId=${country_id}`, {
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

export const getUserMailPreferences = (accessToken) => {
  return fetch(`${API_URL}/users/preferences/mail`, {
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

export const updateMailPreferences = (accessToken, data) => {
  return fetch(`${API_URL}/users/preferences/mail`, {
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};
