export function PeopleIcon(props: Props) {
  const { size } = props;
  const { height = size, width = size } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 6277">
        <g id="Group 6276">
          <path
            id="Vector"
            d="M38 48V43C38 40.3478 36.9464 37.8043 35.0711 35.9289C33.1957 34.0536 30.6522 33 28 33H13C10.3478 33 7.8043 34.0536 5.92893 35.9289C4.05357 37.8043 3 40.3478 3 43V48M53 48V43C52.9983 40.7843 52.2609 38.6319 50.9034 36.8808C49.5459 35.1296 47.6453 33.8789 45.5 33.325M38 3.325C40.151 3.87575 42.0576 5.12675 43.4191 6.88078C44.7806 8.6348 45.5196 10.7921 45.5196 13.0125C45.5196 15.2329 44.7806 17.3902 43.4191 19.1442C42.0576 20.8982 40.151 22.1492 38 22.7M30.5 13C30.5 18.5228 26.0228 23 20.5 23C14.9772 23 10.5 18.5228 10.5 13C10.5 7.47715 14.9772 3 20.5 3C26.0228 3 30.5 7.47715 30.5 13Z"
            stroke="black"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}

interface Props {
  height?: number;
  width?: number;
  size?: number;
}
